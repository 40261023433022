var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex justify-between items-center mb-4"},[_c('div',{staticClass:"title is-4"},[_vm._v("Customers")]),_c('b-button',{attrs:{"type":"is-primary"}},[_vm._v(" Add Customer ")])],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('b-input',{staticClass:"mb-2",attrs:{"icon":"magnify","placeholder":"Filter Customers..."},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),(_vm.selectedCustomers.length)?_c('div',{staticClass:"flex items-center bg-gray-100 p-2 rounded-md border-solid border-gray-200 border-1"},[_c('div',{staticClass:"text-sm font-semibold mr-2"},[_vm._v(" "+_vm._s(_vm.selectedCustomers.length)+" selected ")]),_c('b-button',{attrs:{"type":"mr-2"}},[_vm._v("Delete")]),_c('div',{staticClass:"text-sm ml-auto underline cursor-pointer"},[_vm._v(" Select all customers in store ")])],1):_vm._e(),_c('b-table',{staticClass:"text-sm mb-2 cursor-pointer",attrs:{"data":_vm.rows,"default-sort":['name', 'asc'],"loading":_vm.isLoading,"row-class":function () { return _vm.$style.row; },"custom-is-checked":_vm.isRowSelected,"checkbox-position":"left","checkable":"","hoverable":"","backend-sorting":""},on:{"click":_vm.viewCustomer,"sort":_vm.handleSort,"check":_vm.setSelectedCustomers}},[_c('b-table-column',{attrs:{"field":"name","label":"Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"font-semibold mb-1"},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('div',{staticClass:"text-xs text-gray-500"},[_vm._v(_vm._s(row.email))])]}}])}),_c('b-table-column',{attrs:{"field":"phoneNumber","label":"Phone Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.showPhoneNumber(row))?_c('span',[_vm._v(" "+_vm._s(row.phone)+" ")]):_c('span',[_vm._v(" - ")])]}}])}),_c('b-table-column',{attrs:{"field":"totalOrders","label":"Orders","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getTotalOrdersString(row.totalOrders))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"totalSpent","label":"Total Spent","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getTotalSpentString(row.totalSpent))+" ")]}}])})],1),_c('b-pagination',{attrs:{"current":_vm.currentPage,"per-page":_vm.itemsPerPage,"total":_vm.nbHits,"order":"is-right","simple":""},on:{"update:current":function($event){_vm.currentPage=$event}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }