






















































































import debounce from 'just-debounce-it'
import mixins from 'vue-typed-mixins'
import { getElasticSearchClient } from '@/services/elasticSearch'
import QueryParamsMixin from '@/mixins/QueryParams.mixin'
import pluralize from 'pluralize'
import { Customer, Price } from '../../../../shared/types/types'
import { formatPrice } from '@/lib/utils'

interface Row {
  customerId: string
  name: string
  phone: string
  totalOrders: string
  totalSpent: Price
}

export default mixins(QueryParamsMixin).extend({
  name: 'Customers',
  mixins: [QueryParamsMixin],
  data() {
    return {
      searchInput: '',
      searchResult: [] as Record<string, any>,
      isLoading: false,
      selectedCustomers: [] as any[],
      sort: [
        {
          'firstName.keyword': { order: 'asc' },
        },
        {
          'lastName.keyword': { order: 'asc' },
        },
      ],
    }
  },
  computed: {
    query(): Record<string, any> {
      const query: Record<string, any> = {
        query: {
          bool: {
            filter: [],
            must: [],
          },
        },
        track_total_hits: true,
        sort: this.sort,
        size: this.itemsPerPage,
        from: (this.currentPage - 1) * this.itemsPerPage,
        _source: {
          includes: [
            'customerId',
            'firstName',
            'lastName',
            'shippingAddress.phone',
            'analytics',
          ],
        },
      }
      if (this.search && this.search.length > 0) {
        if (
          this.search.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
        ) {
          // when search input is an email, search for customer email
          query.query.bool.filter.push({
            match: {
              'email.keyword': {
                query: this.search,
                fuzziness: 0,
              },
            },
          })
        } else {
          // when search input is a string, search for customer name and product title
          query.query.bool.must.push({
            match: {
              fullName: {
                query: this.search,
              },
            },
          })
          // delete query.sort
        }
      }
      // if (this.search && this.search.length > 0) {
      //   query.query.bool.filter.push({
      //     multi_match: {
      //       query: this.search,
      //       fields: ['fullName'],
      //       fuzziness: 0,
      //       // prefix_length: 0,
      //     },
      //   })
      // }

      if (
        query.query.bool.filter.length === 0 &&
        query.query.bool.must.length === 0
      ) {
        query.query = {
          match_all: {},
        }
      }

      return query
    },
    nbHits(): number {
      return this.searchResult?.hits?.total?.value || 0
    },
    rows(): Row[] {
      if (!this.searchResult || this.searchResult.length === 0) {
        return []
      }
      return this.searchResult.hits.hits.map((hit: Record<string, any>) => {
        const customer = hit._source
        const row: Row = {
          customerId: customer.customerId,
          name: [customer.firstName, customer.lastName].join(' '),
          phone: customer.shippingAddress?.phone,
          totalOrders: customer.analytics.ordersCount,
          totalSpent: customer.analytics.totalSpent,
        }
        return row
      })
    },
    showPhoneNumber() {
      return (customer: Customer) => customer?.shippingAddress?.phone
    },
  },
  watch: {
    searchInput: {
      immediate: true,
      handler: debounce(function () {
        //@ts-ignore
        this.search = this.searchInput
      }, 500),
    },
    query: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    handleSort(field: string, order: 'asc' | 'desc') {
      const sort: Record<string, any>[] = []
      switch (field) {
        case 'name': {
          sort.push({
            ['firstName.keyword']: { order },
          })
          sort.push({
            ['lastName.keyword']: { order },
          })
          break
        }
        case 'totalOrders': {
          sort.push({
            'analytics.ordersCount': { order },
          })
          break
        }
        case 'totalSpent': {
          sort.push({
            'analytics.totalSpent.amount': { order },
          })
          break
        }
        default: {
          sort.push({
            [field]: { order },
          })
        }
      }

      if (JSON.stringify(sort) === JSON.stringify(this.sort)) {
        return
      }

      // @ts-ignore
      this.sort = sort
    },
    debouncedSearchInput: debounce(function (this: any, value: string) {
      this.search = value
    }, 500),
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post(
        '/customers/_search',
        this.query
      )
      this.searchResult = data
    },
    createCustomer() {
      this.$router.push('/customers/create')
    },
    viewCustomer(customer: Record<string, any>) {
      this.$router.push(`/customers/${customer.customerId}`)
    },
    setLoading(value: boolean) {
      this.isLoading = value
    },
    // TODO: types
    setSelectedCustomers(checkedCustomers: any[]) {
      this.selectedCustomers = checkedCustomers
    },
    // TODO: types
    isRowSelected(a: any, b: any) {
      return a.customerId === b.customerId
    },
    getTotalOrdersString(totalOrders: number) {
      return pluralize('Order', totalOrders, true)
    },
    getTotalSpentString(totalSpent: Price) {
      return formatPrice(totalSpent)
    },
  },
})
